import React  from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box } from "grommet"

const Homepage = ({ location }) => {

  return (
    <Layout>
      <Seo title="Projects" />
      <Box></Box>
    </Layout>
  )
}

export default Homepage
